<script>
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import UsersService from '@src/services/UsersService.js'
import Layout from '@layouts/main'
import UserModal from '@views/users/userModal.vue'
import userFilterModal from '@views/users/userFilterModal'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import FeedbackModal from '@src/components/FeedbackModal'
import FilterBar from '@components/FilterBar'
import validationMixin from '@src/mixins/validation'
import pagination from '@src/mixins/pagination'
import swalFeedbackVue from '@src/mixins/swalFeedback.vue'
import filterVuetable from '@src/mixins/filterVuetable'

import timezones from '@assets/resources/timezones'
import importModal from '@views/importJob/importJobModal'
import userFields from '@src/fields/userFields.vue'
import AddLabelButton from '@/src/components/AddLabelButton.vue'
import ImportButton from '@/src/components/ImportButton.vue'
import ExportButton from '@/src/components/ExportButton.vue'
import FilterLabelButton from '@/src/components/FilterLabelButton.vue'
import MoreTableButton from '@/src/components/MoreTableButton.vue'
import DeleteAllButton from '@/src/components/DeleteAllButton.vue'
import DeleteSelectedButton from '@/src/components/DeleteSelectedButton.vue'
import moment from 'moment';
import { debounce } from 'lodash'

const i18nCommon = 'COMMON'
const i18nKey = 'USERS'

export default {
    components: {
        ApiModeVuetable,
        Breadcrumb,
        PageHeader,
        Layout,
        UserModal,
        userFilterModal,
        ConfirmationFormModal,
        FeedbackModal,
        FilterBar,
        importModal,
        AddLabelButton,
        ImportButton,
        ExportButton,
        FilterLabelButton,
        MoreTableButton,
        DeleteAllButton,
        DeleteSelectedButton,
    },
    mixins: [
        validationMixin,
        pagination,
        swalFeedbackVue,
        filterVuetable,
        userFields,
    ],
    data() {
        return {
            sortOrder: [
                {
                    field: 'name',
                    direction: 'asc',
                },
            ],
            timezones,
            filters: {
                timezone: null,
                active: null,
                roles: [],
                statesAndCities: [],
            },
            i18nCommon: i18nCommon,
            i18nKey: i18nKey,
            modalHasResponseError: false,
            deleteType: 'delete_selected',
            deleteSelectedLoading: false,
            deleteAllLoading: false,
            modalIsEdit: false,
            modal: {
                id: 0,
                name: null,
                email: null,
                password: null,
                active: true,
                password_confirmation: null,
                timezone: null,
                address: null,
                role_id: null,
                city: {
                    id: 0,
                    name: '',
                },
                state: {
                    id: 0,
                    letter: '',
                    name: '',
                    slug: '',
                },
                role: [],
                latitude: null,
                longName: null,
                longitude: null,
                registeredNumber: null,
                complement: null,
                district: null,
                shortName: null,
                postalCode: null,
                number: null,
                placeId: null,
                humanReadableAddress: null,
                phone: null,
                gender: null,
                birthday: null,
                cpf: null,
                rg: null,
                language: null,
                admission_date: null,
                resignation_date: null,
                imei: null,
                imei_active: false,
                contract_type: null,
                workday_id: null,
                workday: null,
                costs: [],
            },
            gModal: {
                address: null,
                city: {
                    id: 0,
                    name: '',
                },
                state: {
                    id: 0,
                    letter: '',
                    name: '',
                    slug: '',
                },
                latitude: null,
                longName: null,
                longitude: null,
                registeredNumber: null,
                complement: null,
                district: null,
                shortName: null,
                postalCode: null,
                number: null,
                placeId: null,
                humanReadableAddress: null,
            },
            data: [],
            perPage: 10,
            css: {},
            filterText: '',
            additionalParameters: {
                with: ['state', 'city', 'roles', 'workday', 'costs'],
                all_users: true,
            },
            submitLoading: false,
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: 'USERS',
                        suffix: 'TITLES.collaborator',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.user',
                        modifier: 2,
                    }),
                    active: true,
                },
            ]
        },
        i18nUsers() {
            return this.getI18nModified({
                prefix: 'USERS',
                suffix: 'TITLES.user',
                modifier: 2,
            })
        },
        i18nEmail() {
            return this.getI18n(i18nCommon, 'email')
        },

        i18nPassword() {
            return this.getI18n(i18nCommon, 'password')
        },

        i18nPasswordConfirmation() {
            return this.getI18n(i18nCommon, 'password_confirmation')
        },

        i18nNewUser() {
            return this.getI18n(i18nKey, 'BUTTONS.new_user')
        },

        i18nTimezone() {
            return this.getI18n(i18nCommon, 'timezone')
        },

        i18nAddress() {
            return this.getI18nModified({
                prefix: i18nCommon,
                suffix: 'address',
                modifier: 1,
            })
        },
        i18nStateAndCity() {
            return this.getI18n(i18nCommon, 'state_and_city')
        },
    },
    watch: {
        filterText: debounce(function (searchText) {
            this.additionalParameters = {
                with: ['state', 'city', 'roles', 'workday', 'costs'],
                search: '',
                all_users: true,
            }
            if (searchText && searchText.length > 0) {
                this.additionalParameters.search = `name:${searchText};email:${searchText};app_version:${searchText};address:${searchText};district:${searchText};complement:${searchText}`
            }
            this.refreshTable()
        }, 1000),
    },
    methods: {
        createAdditionalParameters() {
            let activeSearch = null
            if (this.filters.active && (this.filters.active.value === 'active' || this.filters.active.value === 'not_active')) {
                activeSearch = String(this.filters.active.value === 'active')
            }

            const search = this.createSearch({
                timezone: this.filters.timezone
                    ? this.filters.timezone.id
                    : null,
                active: activeSearch,
            })

            return this.formatParameters({
                with: ['state', 'city', 'roles', 'workday', 'costs'],
                all_users: true,
                search,
                searchJoin: search.search(';') > 0 ? 'and' : '',
                city_id: this.filters.statesAndCities.filter(
                    (id) => typeof id === 'number'
                ),
                state: this.filters.statesAndCities.filter(
                    (id) => typeof id === 'string'
                ),
                role_id: this.filters.roles.map((role) => role.id),
                work_leave: this.filters.active && this.filters.active.value === 'work_leave' ? true : null,
            })
        },

        reloadTable() {
            this.$refs.vuetable.reload()
        },

        refreshTable() {
            this.$refs.vuetable.refresh()
        },

        fetch(url, params) {
            return UsersService.fetchVuetable(url, params)
        },

        filter(appliedFilters) {
            this.filters = appliedFilters
            this.additionalParameters = this.createAdditionalParameters()
            this.refreshTable()
        },

        showFilterModal() {
            this.$nextTick(() =>
                this.$refs.userFilterModal.showModal(this.filters)
            )
        },

        cleanModal() {
            this.$nextTick(() => this.$refs.userModal.cleanModal())
        },

        replaceModalData(data) {
            Object.assign(this.gModal, data)
            Object.assign(this.modal, data)

            Object.assign(this.modal, {
                costs: [],
            })

            if (this.modal.birthday) {
                this.modal.birthday = moment(this.modal.birthday, 'DD/MM/YYYY').toDate();
            }

            if (this.modal.admission_date) {
                this.modal.admission_date = moment(this.modal.admission_date, 'DD/MM/YYYY').toDate();
            }

            if (this.modal.resignation_date) {
                this.modal.resignation_date = moment(this.modal.resignation_date, 'DD/MM/YYYY').toDate();
            }

            Object.assign(this.modal.costs, data.costs)
        },

        showModal() {
            this.$nextTick(() => this.$refs.userModal.showModal())
        },

        showImportModal() {
            this.$nextTick(() => this.$refs.importModal.showModal())
        },

        openModalToCreate() {
            this.modalIsEdit = true
            this.cleanModal()
            this.showModal()
        },

        openModalToEdit(user) {
            this.modalIsEdit = false
            this.replaceModalData(user)
            this.showModal()
        },

        handleDelete(type) {
            if (type === 'delete_selected' && this.deleteSelectedLoading) {
                return
            } else if (type === 'delete_all' && this.deleteAllLoading) {
                return
            }

            this.deleteType = type
            if (type === 'delete_selected') {
                this.$refs.confirmationFormModal.showModal(false, this.deletedSelected)
            }
            else if (type === 'delete_all') {
                this.$refs.confirmationFormModal.showModal(false, this.deletedAllFiltered)
            }
        },

        async deletedSelected(deleteRecord) {
            if (!deleteRecord) {
                return
            }

            this.deleteSelectedLoading = true
            await this.deleteUsers(
                UsersService.deleteMultiple({
                    user_id: this.$refs.vuetable.$refs.vuetable.selectedTo,
                })
            )
        },

        async deletedAllFiltered(deleteRecord) {
            if (!deleteRecord) {
                return
            }

            this.deleteAllLoading = true
            await this.deleteUsers(
                UsersService.deleteMultiple(
                    this.additionalParameters
                )
            )
        },

        async deleteUsers(deleteFunction) {
            const response = await deleteFunction
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$refs.feedbackModal.showModal(statusCode, response.data)
                this.refreshTable()
            } else {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            }
            this.deleteSelectedLoading = false
            this.deleteAllLoading = false
        },

    },
}
</script>

<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3 mb-3">
                <div class="col-sm-6 form-inline">
                    <filter-bar v-model="filterText" />
                    <filter-label-button @onClick="showFilterModal" />
                </div>
                <div class="col-sm-6">
                    <div class="text-sm-right">
                        <add-label-button
                            v-if="$can('CREATE', 'USER')"
                            :title="i18nNewUser"
                            @onClick="openModalToCreate"
                        />
                        <import-button
                            v-if="$can('CREATE', 'IMPORTJOB')"
                            @onClick="showImportModal"
                        />
                        <export-button
                            v-if="$can('CREATE', 'EXPORTJOB')"
                            type="user"
                            :create-parameters="createAdditionalParameters"
                        />
                    </div>
                </div>
            </div>
            <div class="card card-pdv">
                <b-row>
                    <div class="col-sm-6">
                        <PageHeader :title="i18nUsers" />
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right">
                            <delete-selected-button
                                v-if="$can('DELETE', 'USER')"
                                :busy="deleteSelectedLoading"
                                :disabled="deleteAllLoading"
                                @onClick="handleDelete('delete_selected')"
                            />
                            <delete-all-button
                                v-if="$can('DELETE', 'USER')"
                                :busy="deleteAllLoading"
                                :disabled="deleteSelectedLoading"
                                @onClick="handleDelete('delete_all')"
                            />
                        </div>
                    </div>
                </b-row>
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :fields="userFields"
                        :per-page="perPage"
                        :display-selection-records-per-page="true"
                        :additional-parameters="additionalParameters"
                        :sort-order="sortOrder">
                        <template slot="platform" slot-scope="props">
                            {{ formatPlatform(props.rowData.platform, props.rowData.platform_version) }}
                        </template>
                        <template slot="status" slot-scope="props">
                            <b-badge
                                v-if="props.rowData.is_work_leave"
                                variant="danger"
                            >
                                {{ getI18n(i18nCommon, 'license') }}
                            </b-badge>
                            <b-badge
                                v-else-if="props.rowData.active"
                                variant="primary"
                            >
                                {{ getI18n(i18nCommon, 'active') }}
                            </b-badge>
                            <b-badge v-else variant="secondary">
                                {{ getI18n(i18nCommon, 'not_active') }}
                            </b-badge>
                        </template>
                        <template slot="actions" slot-scope="props">
                            <div class="table-button-container">
                                <more-table-button
                                    @onClick="openModalToEdit(props.rowData)"
                                />
                            </div>
                        </template>
                    </api-mode-vuetable>
                </div>
            </div>
        </div>
        <confirmation-form-modal
            ref="confirmationFormModal"
            :title="i18nUsers"
            :operation="deleteType"
        />
        <feedback-modal
            ref="feedbackModal"
            :title="i18nUsers"
            :operation="deleteType"
        />
        <import-modal
            ref="importModal"
            :csv-type="'users'"
            :title="
                getI18nModified({
                    prefix: 'IMPORT_JOBS',
                    suffix: 'TITLES.users',
                    modifier: 2,
                })
            "
        />

        <user-modal
            ref="userModal"
            :modal-is-edit="modalIsEdit"
            :modal="modal"
            :google-modal="gModal"
            @refresh="reloadTable"
        />

        <user-filter-modal ref="userFilterModal" :handle-submit="filter" />
    </Layout>
</template>
