<script>
export default {
    data() {
        return {
            genders: [
                {
                    value: 'male',
                    text: this.getI18n('USERS', 'GENDERS.male'),
                },
                {
                    value: 'female',
                    text: this.getI18n('USERS', 'GENDERS.female'),
                },
                {
                    value: 'other',
                    text: this.getI18n('USERS', 'GENDERS.other'),
                },
            ],
        }
    },
}
</script>
